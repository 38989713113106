<template>
  <div style="display: contents;">
    <v-btn small elevation="0" color="primary" @click="$emit('save')">
      {{ title }}
    </v-btn>
    <v-btn
      v-if="isEdit"
      small
      elevation="0"
      outlined
      color="grey"
      @click="SET_IS_EDIT(false)"
    >
      취소
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("cardDetail", ["isEdit"]),
    title() {
      return this.isEdit ? "저장" : "수정";
    }
  },
  methods: {
    ...mapMutations("cardDetail", ["SET_IS_EDIT"])
  }
};
</script>
