<template>
  <v-container>
    <!-- 그룹 -->
    <v-row class="text-center justify-center">
      <v-col cols="12" class="py-0">
        <v-select
          prepend-icon="mdi-folder-outline"
          ref="customerGroupList"
          v-model="targetGroup"
          :items="customerGroupList"
          item-value="id"
          return-object
          chips
          :label="$t('contact.84')"
          required
          :rules="rule"
        >
          <template v-slot:selection="{ item }">
            <span>{{ item.groupName }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span :style="`padding-left: ${item.depth * 10}px`">{{
              item.depth > 0 ? "└ " + item.groupName : item.groupName
            }}</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" class="py-0 d-flex justify-center">
        <v-checkbox v-model="shouldDeleteOriginalData">
          <template v-slot:label>
            <div v-text="$t('contact.86')" />
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인버튼"
    }
  },
  watch: {
    confirm: function(next) {
      if (next) {
        this.copyCardFunc();
        this.$emit("update:confirm", false);
      }
    }
  },
  async mounted() {
    // 공용 주소록 그룹 리스트 조회
    this.customerGroupList = await this.getFlatCustomerGroupList();
    this.$refs["customerGroupList"].$refs.menu.updateDimensions();
    // cardList 에서 선택된 연락처 조회
    const { selectedCardIdList } = this.getParams;
    this.selectedCardIdList = selectedCardIdList;
  },
  data: () => ({
    targetGroup: {},
    customerGroupList: [],
    selectedCardIdList: [],
    shouldDeleteOriginalData: true,
    rule: [v => !!v || i18n.t("contact.85")]
  }),
  computed: {
    ...mapGetters("contactDialog", ["getParams"])
  },
  methods: {
    ...mapMutations("contactDialog", ["CLOSE_DIALOG"]),
    ...mapMutations("selectBox", ["RESET_SELECT_BOX"]),
    ...mapActions("confirm", { confirmDialog: "confirm" }),
    ...mapActions("card", ["copyCardsToCustomer"]),
    ...mapActions("customerGroup", ["getFlatCustomerGroupList"]),
    copyCardFunc() {
      const checkGroup = this.$refs.customerGroupList.validate(true);
      if (!checkGroup) {
        return;
      }
      const cardCnt = this.selectedCardIdList.length;
      const { id, groupName } = this.targetGroup;
      this.confirmDialog({
        message: i18n.t("contact.87", { count: cardCnt, groupName: groupName }),
        callback: async () => {
          const result = await this.copyCardsToCustomer({
            targetGroupId: id,
            cardIdList: this.selectedCardIdList,
            shouldDeleteOriginalData: this.shouldDeleteOriginalData
          });
          if (result) {
            this.clearSelectedItems();
            this.CLOSE_DIALOG();
          }
        }
      });
    },
    clearSelectedItems() {
      this.RESET_SELECT_BOX();
    }
  }
};
</script>
