<template>
  <div class="cr-config-wrapper pa-2">
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 cr-config-title d-flex align-center grey--text text--darken-2"
        cols="12"
        sm="4"
        v-text="$t('contact.5')"
      ></v-col>
      <v-col cols="12" sm="8">
        <v-select
          style="max-width: 400px;"
          dense
          outlined
          hide-details
          item-text="name"
          item-value="value"
          v-model="firstPage"
          :items="firstGroupList"
        ></v-select>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 d-flex align-center cr-config-title grey--text text--darken-2"
        cols="12"
        sm="4"
        v-text="$t('contact.30')"
      ></v-col>
      <v-col cols="12" sm="8">
        <v-select
          style="max-width: 400px;"
          dense
          outlined
          hide-details
          :items="pageSizeList"
          v-model="pageSize"
        ></v-select>
      </v-col>
    </v-row>
    <v-divider />
    <v-row no-gutters class="mt-3">
      <v-col class="d-flex justify-end">
        <v-btn
          class="mr-3"
          color="primary"
          depressed
          :disabled="isDisabled"
          @click="updateConfig"
          v-text="$t('contact.저장')"
        />
        <v-btn
          text
          depressed
          @click="cancel"
          :disabled="isDisabled"
          v-text="$t('common.취소')"
        />
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.v-data-table >>> tbody > tr:hover:not(.v-data-table__expanded__content) {
  background: #ffffff !important;
}

.cr-config-title {
  font-size: 1rem !important;
  font-weight: bold;
}
</style>

<script>
import i18n from "@/_locales";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("contactConfig", [
      "getFirstPage",
      "getSort",
      "getPageSize",
      "getCurrentConfig"
    ]),

    firstPage: {
      get() {
        return this.getFirstPage;
      },
      set(firstPage) {
        this.SET_FIRST_PAGE(firstPage);
      }
    },
    pageSize: {
      get() {
        return this.getPageSize;
      },
      set(pageSize) {
        this.SET_PAGE_SIZE(pageSize);
      }
    }
  },

  watch: {
    getCurrentConfig() {
      this.configChanged();
    },
    firstPage() {
      this.configChanged();
    },
    pageSize() {
      this.configChanged();
    }
  },

  methods: {
    ...mapMutations("contactConfig", ["SET_FIRST_PAGE", "SET_PAGE_SIZE"]),
    ...mapActions("contactConfig", ["updateConfig"]),

    configChanged() {
      this.isDisabled =
        this.getCurrentConfig.firstPage == this.getFirstPage &&
        this.getCurrentConfig.pageSize == this.getPageSize;
    },

    cancel() {
      const { firstPage, pageSize } = this.getCurrentConfig;
      this.SET_FIRST_PAGE(firstPage);
      this.SET_PAGE_SIZE(pageSize);
    },
    reset() {
      this.cancel();
    }
  },

  data() {
    return {
      firstGroupList: [
        { value: "all", name: i18n.t("contact.전체") },
        { value: "important", name: i18n.t("contact.8") }
      ],
      pageSizeList: [10, 20, 30, 40, 50],
      isDisabled: true
    };
  }
};
</script>
