<template>
  <v-navigation-drawer
    absolute
    right
    v-show="drawer"
    v-model="drawer"
    width="320"
    class="cr-contact-setting"
  >
    <v-card class="mx-auto" width="320" height="100%">
      <div class="d-flex flex-row justify-space-between px-6 pt-4">
        <div class="font-weight-bold text-h6" v-text="$t('common.설정')"></div>
        <div>
          <v-btn icon @click="SET_SHOW_SETTING(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>

      <v-card-actions class="px-6">
        <v-btn
          block
          depressed
          color="primary"
          @click="
            $router.push({
              name: 'contact_config',
              params: { tabType: 'list' }
            });
            SET_SHOW_SETTING(false);
          "
          v-text="$t('contact.7')"
        >
        </v-btn>
      </v-card-actions>
      <v-divider />

      <div style="height:calc(100% - 108px); overflow-y:auto;">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="mb-2" v-text="$t('contact.5')">
            </v-list-item-title>
            <v-select
              dense
              outlined
              hide-details
              item-text="name"
              item-value="value"
              :items="serializationGroupList"
              v-model="firstPage"
              @change="updateFirstPage"
            ></v-select>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="mb-2" v-text="$t('contact.6')">
            </v-list-item-title>
            <v-select
              dense
              outlined
              hide-details
              item-text="text"
              item-value="value"
              :items="pageSizeList"
              v-model="pageSize"
              @change="updateConfigPageSize"
            ></v-select>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapMutations, mapActions } from "vuex";

import { virtualContactGroupConverter } from "@/contact/constant/contactGroupType";

export default {
  data: () => ({
    serializationGroupList: [
      { value: "all", name: i18n.t("contact.전체") },
      { value: "important", name: i18n.t("contact.8") }
    ],
    pageSizeList: [
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "30", value: 30 },
      { text: "40", value: 40 },
      { text: "50", value: 50 }
    ]
  }),
  computed: {
    ...mapGetters("contactRoute", ["getCardListInfo", "getPage"]),
    ...mapGetters("contactLayout", ["getShowSetting"]),
    ...mapGetters("contactConfig", ["getFirstPage", "getPageSize"]),
    drawer: {
      get() {
        return this.getShowSetting;
      },
      set(drawer) {
        this.SET_SHOW_SETTING(drawer);
      }
    },
    firstPage: {
      get() {
        return this.getFirstPage;
      },
      set(firstPage) {
        this.SET_FIRST_PAGE(firstPage);
      }
    },
    pageSize: {
      get() {
        return this.getPageSize;
      },
      set(pageSize) {
        this.SET_PAGE_SIZE(pageSize);
      }
    }
  },
  methods: {
    ...mapMutations("contactLayout", ["SET_SHOW_SETTING"]),
    ...mapMutations("contactConfig", ["SET_FIRST_PAGE", "SET_PAGE_SIZE"]),
    ...mapActions("contactConfig", ["updateFirstPage", "updatePageSize"]),
    async updateConfigPageSize() {
      const result = await this.updatePageSize();
      if (result) {
        if (this.$route.name == "contact_config") return;

        const { params } = this.$route;
        const { contactGroupId } = this.getCardListInfo;
        const { actionObj } = params;

        if (actionObj) {
          let newActionObj = JSON.parse(actionObj);
          newActionObj.pageSize = this.getPageSize;
          newActionObj = JSON.stringify(newActionObj);
          this.$router.push({
            name: "contact_list_action",
            params: {
              contactGroupId: virtualContactGroupConverter(contactGroupId),
              page: 1,
              actionObj: newActionObj
            }
          });
        }

        this.$router.push({
          name: "contact_list",
          params: {
            contactGroupId: virtualContactGroupConverter(contactGroupId),
            page: 1
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer.cr-contact-setting ::v-deep {
  border-left: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 0px 8px 0px 0px;

  .v-list-item > .v-list-item__content {
    padding: 12px 10px;
    .v-input--selection-controls {
      margin-top: 0px;
    }
  }
}
</style>
