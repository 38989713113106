<template>
  <v-card-text class="pb-0">
    <ContactGroupAddDialog
      v-if="dialogType == 'addContactGroup'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CardAddDialog
      v-if="dialogType == 'addCard'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CardUpdateDialog
      v-if="dialogType == 'updateCard'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CardViewDialog
      v-if="dialogType == 'viewCard'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CardMergeDialog
      v-if="dialogType == 'mergeCard'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CardCopyDialog
      v-if="dialogType == 'copyCard'"
      v-bind="$props"
      v-on="$listeners"
    />
    <CardCopyToCustomerDialog
      v-if="dialogType == 'copyCardToCustomer'"
      v-bind="$props"
      v-on="$listeners"
    />
  </v-card-text>
</template>

<script>
import { mapGetters } from "vuex";

import ContactGroupAddDialog from "./ContactGroupAddDialog";
import CardAddDialog from "./CardAddDialog";
import CardUpdateDialog from "./CardUpdateDialog";
import CardViewDialog from "./CardViewDialog";
import CardMergeDialog from "./CardMergeDialog";
import CardCopyDialog from "./CardCopyDialog";
import CardCopyToCustomerDialog from "./CardCopyToCustomerDialog";
export default {
  components: {
    ContactGroupAddDialog,
    CardAddDialog,
    CardUpdateDialog,
    CardViewDialog,
    CardMergeDialog,
    CardCopyDialog,
    CardCopyToCustomerDialog
  },
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인버튼"
    },
    confirmDisabled: {
      default: false,
      type: Boolean,
      description: "확인 버튼 Disabled 여부"
    },
    expand: {
      default: false,
      type: Boolean,
      description: "더보기"
    }
  },
  computed: {
    ...mapGetters("contactDialog", ["dialogType"])
  }
};
</script>
