<template>
  <div class="cr-config-wrapper pa-2">
    <v-row class="no-gutters mt-2 px-3">
      <v-col class="d-flex align-center" cols="12">
        <div class="cr-config-title" v-text="$t('contact.13')"></div>
      </v-col>
      <v-col cols="12" class="import-expain my-2">
        <div class="text-caption" v-text="$t('contact.14')"></div>
      </v-col>
      <div class="mb-4 d-flex">
        <v-select
          v-model="fileType"
          :items="fileTypeItems"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          class="mr-4"
          style="max-width:240px"
        ></v-select>

        <!-- 셈플 버튼 -->
        <v-btn
          v-if="fileType == 'default'"
          href="/files/sampleFile.xls"
          download
          text
          outlined
          tile
          style="height:40px;"
        >
          {{ $t("contact.15") }}
        </v-btn>

        <!-- 메일 플러그 파일 인코딩 -->
        <v-select
          v-if="usingEncoding"
          v-model="fileEncoding"
          :items="fileEncodingItems"
          outlined
          dense
          hide-details
          style="max-width:140px"
        ></v-select>
      </div>
      <v-col
        cols="12"
        class="cr-file-box-wrapper d-flex align-center justify-center py-8"
        @click="openFileInput"
      >
        <v-text-field
          background-color="white"
          dense
          outlined
          hide-details
          required
          autofocus
          disabled
          append-icon="mdi-tray-arrow-up"
          ref="newNameFolder"
          v-model="fileName"
        ></v-text-field>
        <input
          v-show="false"
          type="file"
          ref="fileInput"
          @change="changeFileInput"
        />
      </v-col>
      <v-col cols="12" class="my-10 d-flex align-center justify-center">
        <v-btn
          class="loadBtn font-weight-bold"
          depressed
          color="primary"
          :disabled="!isExistFile"
          @click="loadFile"
        >
          {{ $t("contact.불러오기") }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="no-gutters pt-2 mt-4 px-3">
      <v-col class="d-flex align-center" cols="12">
        <div class="cr-config-title" v-text="$t('contact.16')"></div>
      </v-col>
      <v-row class="my-2 no-gutters d-flex align-center">
        <v-col
          class="pb-3 pb-sm-0 cr-config-title d-flex align-center grey--text text--darken-2"
          cols="12"
          sm="4"
        >
          {{ $t("contact.17") }}
        </v-col>
        <v-col class="cr-select-group" cols="12" sm="8">
          <v-select
            dense
            outlined
            hide-details
            item-text="groupName"
            item-value="id"
            :items="groupList"
            v-model="selectedGroup"
          ></v-select>
        </v-col>
      </v-row>
      <v-col cols="12" class="import-expain my-2">
        <div class="text-caption">
          * <strong class="red--text">{{ $t("contact.18") }}</strong
          >{{ $t("contact.19") }}
        </div>
        <div class="text-caption">* {{ $t("contact.20") }}</div>
        <div class="text-caption">* {{ $t("contact.71") }}</div>
      </v-col>

      <v-col cols="12" class="cr-contact-list-wrapper">
        <v-data-table
          show-select
          hide-default-footer
          :headers="headers"
          :items="contactList"
          :items-per-page="-1"
          item-key="idx"
          v-model="selectedItems"
          style="max-height:400px; overflow:hidden; overflow-y:auto;"
        >
          <template v-slot:[`item.name`]="{ item }">
            <div>
              {{ item.firstName + item.lastName }}
            </div>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <div>
              {{ item.tel_cell }}
            </div>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <div>
              {{ item.email }}
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="my-10 d-flex align-center justify-center">
        <v-btn
          class="font-weight-bold"
          color="primary"
          depressed
          :disabled="selectedItems.length > 0 ? false : true"
          @click="saveContact"
        >
          {{ $t("contact.저장하기") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular
        class="mb-5 mx-auto d-flex justify-center"
        indeterminate
        size="64"
      ></v-progress-circular>
      <div class="font-weight-bold" style="text-align:center;">
        <div v-text="$t('contact.21')"></div>
        <div v-text="$t('contact.22')"></div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapActions } from "vuex";
import { cardRestore, cardRestoreSave } from "@/contact/api/card.api";
import { getPrivateContactGroupList } from "@/contact/api/group.api";

export default {
  data: () => ({
    file: [],
    fileName: "",
    useFileExt: ["xls", "csv"],
    overlay: false,

    headers: [
      {
        text: i18n.t("contact.이름"),
        align: "start",
        sortable: false,
        value: "name",
        width: 250
      },
      {
        text: i18n.t("contact.이메일"),
        align: "start",
        sortable: false,
        value: "email"
      },
      {
        text: i18n.t("contact.전화번호"),
        align: "start",
        sortable: false,
        value: "phone"
      },
      {
        text: i18n.t("contact.회사"),
        align: "start",
        sortable: false,
        value: "company"
      },
      {
        text: i18n.t("contact.그룹"),
        align: "start",
        sortable: false,
        value: "group"
      }
    ],
    contactList: [],
    groupList: [{ id: 0, groupName: i18n.t("contact.23") }],
    selectedGroup: 0,
    selectedItems: [],

    fileType: "default",
    fileTypeItems: [
      {
        id: "default",
        name: i18n.t("contact.72")
      },
      {
        id: "mailplug",
        name: i18n.t("contact.73")
      },
      {
        id: "google-csv",
        name: i18n.t("contact.74")
      }
    ],
    fileEncoding: "EUC-KR",
    fileEncodingItems: ["EUC-KR", "UTF-8"]
  }),
  async mounted() {
    const { data = [] } = await getPrivateContactGroupList();
    this.groupList = [...this.groupList, ...data];
  },
  watch: {
    fileType(value) {
      if (value == "mailplug") {
        this.fileEncoding = "EUC-KR";
      } else if (value == "google-csv") {
        this.fileEncoding = "UTF-8";
      }
    }
  },
  computed: {
    isExistFile() {
      return this.file.length > 0;
    },
    targetFile() {
      return this.file[0];
    },
    usingEncoding() {
      return this.fileType == "mailplug" || this.fileType == "google-csv";
    }
  },
  methods: {
    ...mapActions("group", ["getDefaultContactGroupList"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    openFileInput() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    changeFileInput(e) {
      const file = e.target.files;
      if (file.length > 0) {
        // 파일 확장자 체크
        const extCheck = this.extValidationCheck(file[0]);
        if (!extCheck) return;

        this.file = file;
        this.fileName = file[0].name;
      }
    },
    // 불러오기
    async loadFile() {
      // 1. 파일 있는지 검사
      if (!this.targetFile) {
        return this.openSnackbar({
          message: i18n.t("contact.24")
        });
      }
      // 2. 확장자 유효성검사
      const extCheck = this.extValidationCheck(this.targetFile);
      if (!extCheck) return;

      this.overlay = true;

      const { data, status } = await cardRestore({
        part: this.targetFile,
        type: this.fileType,
        charset: this.fileEncoding
      });
      const messages = {
        ERROR: i18n.t("contact.25"),
        SUCCESS: i18n.t("contact.26")
      };
      const type = status != 200 ? "ERROR" : data.result ? "SUCCESS" : "ERROR";
      if (type == "SUCCESS") {
        this.file = [];
        this.fileName = "";

        this.contactList = data.list.map((c, index) => {
          return {
            idx: index,
            ...c
          };
        });
        this.selectedItems = [];
      }
      this.overlay = false;
      this.openSnackbar({
        type,
        message: messages[type]
      });
    },
    // 파일 확장자 검사
    extValidationCheck(file) {
      const fileExt = file.name
        .split(".")
        .pop()
        .toLowerCase();
      const index = this.useFileExt.findIndex(ext => ext == fileExt);

      if (index < 0) {
        this.openSnackbar({
          message: i18n.t("contact.27")
        });
        return false;
      }
      return true;
    },

    // 주소록 저장하기
    async saveContact() {
      if (this.contactList.length == 0) return;
      this.overlay = true;

      const params = {
        data: this.selectedItems,
        contactGroupIdList: this.selectedGroup != 0 ? [this.selectedGroup] : []
      };
      const { data, status } = await cardRestoreSave(params);
      const messages = {
        ERROR: i18n.t("contact.28"),
        SUCCESS: i18n.t("contact.29")
      };
      const type = status != 200 ? "ERROR" : data.result ? "SUCCESS" : "ERROR";
      // 저장에 성공한 경우 선택된연락처 리스트에서 삭제
      if (type == "SUCCESS") {
        this.selectedItems.forEach(item => {
          const index = this.contactList.findIndex(
            listItem => listItem.idx == item.idx
          );
          if (index > -1) this.contactList.splice(index, 1);
        });
        this.selectedItems = [];
        // 그룹 목록 갱신
        await this.getDefaultContactGroupList();
      }
      this.openSnackbar({
        type,
        message: messages[type]
      });
      this.overlay = false;
    },
    reset() {
      this.file = [];
      this.fileName = "";
      this.overlay = false;

      this.contactList = [];
      this.selectedGroup = 0;
      this.selectedItems = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-config-wrapper ::v-deep {
  .cr-config-title {
    font-size: 1rem !important;
    font-weight: bold;
  }
  .cr-file-box-wrapper {
    border: 1px solid #eee;
    background-color: #f7f7f7;
    cursor: pointer;
    .v-input {
      max-width: 350px;
      font-weight: 500;
    }
  }
  .loadBtn {
    background-color: #f5f8fa;
  }
  .cr-select-group {
    .v-input {
      max-width: 400px;
    }
  }
  .cr-contact-list-wrapper {
    & .v-data-table--mobile {
      table > thead > tr > th {
        padding: 0px;
        padding-right: 16px;
      }
    }
    .v-data-table {
      border: thin solid rgba(0, 0, 0, 0.12);
      border-radius: 0px;
    }
  }
}
</style>
