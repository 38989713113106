<template>
  <v-container>
    <Detail />
    <Hub />
  </v-container>
</template>

<style lang="scss" scoped>
.cr-contact-unified {
  position: absolute;
  top: 0;
  left: 400px;
  right: 0;
  bottom: 0;
  display: flex;
  min-width: 800px;
  overflow-x: auto;
}
</style>

<script>
import Detail from "./detail";
import Hub from "../../detail/unified/hub";

export default {
  components: { Detail, Hub }
};
</script>
