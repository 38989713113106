<template>
  <div class="cr-selectbox pl-5">
    <div class="select-count mt-5 body-2 font-weight-light">
      <span v-text="$t('common.56', { value: getSelectBoxItems.length })">
      </span>

      <v-icon small class="ml-0" @click="resetBox">
        mdi-close
      </v-icon>
    </div>
    <div class="select-info ml-2 mt-4">
      <v-chip
        class="mb-3 mr-3"
        v-for="item in getSelectBoxItems"
        :key="item.id"
        close
        label
        :ripple="false"
        color="primary"
        text-color="white"
        @click:close="selectCancel(item)"
        close-icon="mdi-close"
      >
        <span class="text-truncate">
          {{
            moduleType == "contact"
              ? item.userName
              : moduleType == "drive"
              ? item.fileName
              : moduleType == "driveDialog"
              ? item.fileName
              : item.id
          }}</span
        >
      </v-chip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-selectbox {
  border-top: 1px solid #e5e5e6;
  border-bottom: 1px solid #e5e5e6;
  height: 90px;
  display: flex;
  .select-count {
    width: 100px;
    > span {
      color: #b71c1c;
    }
    .v-icon {
      vertical-align: text-bottom;
    }
  }
  .select-info {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: ["moduleType"],
  created() {
    if (this.moduleType == "driveDialog") return;
    // selectBox 생성시 css 수정을 위한 class추가
    const tableDom = document.getElementsByClassName(
      "v-data-table__wrapper"
    )[0];
    if (!tableDom) return;
    tableDom.className = "v-data-table__wrapper cr-use-selectbox";
  },
  destroyed() {
    this.RESET_SELECT_BOX();
    // selectBox 없어질때 css 원래대로 돌아가기 위한 class추가
    if (this.moduleType == "driveDialog") return;
    const tableDom = document.getElementsByClassName(
      "v-data-table__wrapper"
    )[0];
    if (!tableDom) return;
    tableDom.className = "v-data-table__wrapper";
  },
  watch: {
    getSelectBoxItems(items) {
      if (items.length <= 0) this.RESET_SELECT_BOX();
    }
  },
  computed: {
    ...mapGetters("selectBox", ["getSelectBoxItems"])
  },
  methods: {
    ...mapMutations("selectBox", ["SET_SELECT_ITEM", "RESET_SELECT_BOX"]),
    // box안 초기화
    resetBox() {
      this.RESET_SELECT_BOX();
      this.$emit("resetSelectedItems");
    },
    // item 개별 선택 해제
    selectCancel(item) {
      this.SET_SELECT_ITEM(item);
      this.$emit("selectedItemCancel", item);
    }
  }
};
</script>
