<template>
  <Toolbar
    :items="items"
    :rightItems="rightItems"
    :checkbox.sync="checkbox"
    :indeterminate="indeterminate"
    @update:checkbox="c => $emit('toggleCheckbox', c)"
  />
</template>

<script>
import i18n from "@/_locales";
import { mapMutations, mapActions, mapGetters } from "vuex";
import Toolbar from "@/commons/views/toolbar/Toolbar";

export default {
  props: ["selectedItems", "showConsonant"],
  components: { Toolbar },
  computed: {
    ...mapGetters("card", ["getDefaultHeaders", "getHeaders", "getCardList"]),
    ...mapGetters("auth", ["getCompanyInfo"]),
    ...mapGetters("contactConfig", ["isUseSelectBox"]),
    ...mapGetters("contactRoute", ["getCardListInfo"]),
    ...mapGetters("selectBox", ["getSelectBoxItems"]),

    items: function() {
      // selectBox사용에 따른 selectItems설정
      const selectedCards = this.isUseSelectBox
        ? this.getSelectBoxItems
        : this.selectedItems;
      const { length: selectedLength } = selectedCards;
      const singleDisabled = selectedLength < 1;
      const multipleDisabled = selectedLength < 2;
      const singleOnlyDisabled = selectedLength != 1;

      const items = [
        {
          title: i18n.t("common.수정"),
          disabled: singleOnlyDisabled,
          click: () =>
            this.$emit(
              "openCardUpdateDialog",
              { stopPropagation: () => {} },
              selectedCards[0].id
            )
        },
        {
          title: i18n.t("contact.합치기"),
          disabled: multipleDisabled,
          click: () => this.$emit("merge")
        },
        {
          title: i18n.t("common.복사"),
          disabled: singleDisabled,
          click: () => this.$emit("openCopyCardDialog")
        },
        {
          title: i18n.t("common.삭제"),
          disabled: singleDisabled,
          click: () => this.$emit("deleteCardList", "move")
        },
        {
          title: i18n.t("contact.메일쓰기"),
          disabled: singleDisabled,
          click: () => this.$emit("openMailWritePopup", selectedCards)
        },
        {
          title: i18n.t("contact.인쇄"),
          class: "cr-tutorial-contact-print",
          click: () => this.$emit("print")
        }
      ];

      // 고객주소록 기능이 on 이면 복사 버튼 추가
      if (this.getCompanyInfo.useCustomer) {
        items.push({
          title: i18n.t("contact.고객주소록_복사"),
          disabled: singleDisabled,
          click: () => this.$emit("copyCardToCustomerDialog")
        });
      }

      return items;
    },
    rightItems: function() {
      const headerList = [];
      this.getDefaultHeaders.forEach((header, index) => {
        if (!header.default) {
          headerList.push({
            title: header.text,
            check: () => this.updateConfigHideColumns({ index, header }),
            checked: header.use
          });
        }
      });

      return [
        {
          title: "필터", // i18n.t("contact.초성"),
          click: () => this.$emit("update:showConsonant", !this.showConsonant)
        } /*,
        {
          class: "cr-tutorial-contact-filter",
          title: i18n.t("contact.목록노출"),
          type: "menu",
          vModel: "filter",
          closeOnContentClick: true,
          checkbox: true,
          children: headerList
        }*/
      ];
    }
  },
  methods: {
    ...mapMutations("contactConfig", ["SET_PAGE_SIZE"]),
    ...mapActions("contactConfig", ["updateConfig", "updateConfigHideColumns"])
  },
  watch: {
    selectedItems(items) {
      const a = this.getCardList.filter(c => !!items.find(i => i.id == c.id));
      if (a.length != 0 && a.length === this.getCardList.length) {
        this.checkbox = true;
        this.indeterminate = false;
      } else if (a.length == 0) {
        this.checkbox = false;
        this.indeterminate = false;
      } else {
        this.indeterminate = true;
      }
    }
  },
  data: () => ({
    checkbox: false,
    indeterminate: false
  })
};
</script>
