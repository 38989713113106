<template>
  <v-row class="cr-hub">
    <v-col cols="12" :class="{ 'pt-0': isMini }">
      <MailHistory />
    </v-col>
  </v-row>
</template>

<script>
import MailHistory from "./MailHistory.vue";

export default {
  components: { MailHistory },
  computed: {
    isMini() {
      return this.$vuetify.breakpoint.mdAndDown;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-hub::v-deep {
  .cr-part {
    border-radius: 8px;
    border: thin solid rgba(0, 0, 0, 0.08);
    background-color: white;
  }
}
</style>
