<template>
  <div class="py-2 cr-draggable-list" :data-id="item.id">
    <div class="font-weight-bold">
      {{ item.userName }}
    </div>
    <div>{{ item.email }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
