<template>
  <!-- 주소록 리스트 -->
  <div class="d-flex">
    <v-data-table
      show-select
      fixed-header
      hide-default-header
      hide-default-footer
      item-key="id"
      :item-class="itemClass"
      :loading="isLoading"
      :headers="headers"
      :items="getCardList"
      :items-per-page="getPageSize"
      :server-items-length="getTotalCount"
      v-model="_selectedItems"
      @page-count="pageCount = $event"
      @item-selected="itemSelected"
      @click:row="r => getContactDetail(r.id)"
      draggable="true"
      v-drag-and-drop="{
        /**
         * drag&drop directive
         *
         * DRAG_COMP    - directive를 사용하는 컴포넌트 타이틀
         * genHelper    - helper 생성 함수 / Argument(event, setDragInfo)) / return element
         * dragstart    - dragstart / Argument(event, setDragInfo))
         * genDropzone  - 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)
         * markDropzone - 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)
         * mouseup      - drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))
         */
        DRAG_COMP: 'list',
        genHelper,
        dragstart,
        genDropzone,
        markDropzone,
        mouseup
      }"
    >
      <template v-slot:[`item.importantStatus`]="{ item }">
        <ImportantBtn :id="item.id" />
      </template>
      <template v-slot:[`item.userName`]="{ item }">
        <ListItem :item="item" v-bind="$props" />
      </template>
      <template v-slot:footer>
        <Pagination
          v-if="pageCount > 0"
          type="list"
          :page.sync="page"
          :pageSize="getPageSize"
          :blockSize="3"
          :totalElements="getTotalCount"
          @pageClick="page => pageClick(page)"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { moveCard } from "@/contact/api/card.api";
import { virtualContactGroupConverter } from "@/contact/constant/contactGroupType";
import ListItem from "./item";
import ImportantBtn from "@/contact/views/components/list/common/ImportantBtn.vue";
import Pagination from "@/commons/views/Pagination";

export default {
  components: { ListItem, ImportantBtn, Pagination },
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      headers: [
        { value: "importantStatus", cellClass: "importantStatus" },
        { value: "userName" }
      ]
    };
  },
  watch: {
    getPage(page) {
      this.page = page;
    }
  },
  computed: {
    ...mapGetters("dragDrop", ["dragComp", "dragMode"]),
    ...mapGetters("cardDetail", ["detail"]),
    ...mapGetters("contactConfig", ["getPageSize", "isUseSelectBox"]),
    ...mapGetters("contactRoute", ["getPage", "getCardListInfo"]),
    ...mapGetters("card", ["getCardList", "getTotalCount", "isLoading"]),

    _selectedItems: {
      get() {
        return this.selectedItems;
      },
      set(selectedItems) {
        this.$emit("update:selectedItems", selectedItems);
      }
    }
  },
  methods: {
    ...mapMutations("selectBox", ["SET_SELECT_ITEM", "RESET_SELECT_BOX"]),
    ...mapActions("card", ["updateImportantStatus"]),
    ...mapActions("cardDetail", ["getContactDetail"]),
    itemClass(item) {
      let cls = "item-row";
      if (this.detail?.id === item.id) {
        cls += " cr-selected";
      }

      return cls;
    },
    pageClick(page) {
      // routing시 전달되는 parameter가 있는 지 체크
      const { actionObj } = this.$route.params;
      const name = actionObj ? "contact_list_action" : "contact_list";
      let { contactGroupId } = this.getCardListInfo;
      contactGroupId = virtualContactGroupConverter(contactGroupId);

      // 페이지 정보 를 가지고 라우팅 진행
      this.$router.push({ name, params: { contactGroupId, page, actionObj } });
    },
    itemSelected(selected) {
      if (!this.isUseSelectBox) return;
      this.SET_SELECT_ITEM(selected.item);
    },

    // drag & drop
    genHelper(e) {
      const target = e.target.closest(".cr-draggable-list");
      if (!target) return null;

      const ghost = document.createElement("div");
      ghost.setAttribute("data-id", target.getAttribute("data-id"));
      ghost.classList.add("dnd-ghost");
      ghost.innerHTML =
        '<div class="dnd-node">' +
        ' <div class="dnd-icon">' +
        '   <i aria-hidden="true" class="v-icon mdi mdi-account-box-outline theme--light"></i>' +
        " </div>" +
        ' <div class="dnd-text"><span class="num"></span><span class="txt">개 주소이동</span></div>' +
        "</div>";

      return ghost;
    },
    dragstart() {
      const crHelper = document.getElementById("crHelper");
      const draggedId = crHelper.getAttribute("data-id");
      const [draggedItem] = this._selectedItems.filter(c => c.id == draggedId);

      let length = 0;
      if (typeof draggedItem === "undefined") {
        const draggedItems = [];
        this.getCardList.forEach(c => {
          if (c.id == draggedId) {
            draggedItems.push(c);
          }
        });

        if (draggedItems.length === 0) return;

        this.RESET_SELECT_BOX();
        this.SET_SELECT_ITEM(draggedItems[0]);
        this._selectedItems = [...draggedItems];
        length = 1;
      } else {
        length = this._selectedItems.length;
      }

      const el = crHelper.querySelector(".num");
      if (el) el.innerText = length;
    },
    genDropzone() {
      if (this.dragComp !== "list") return;

      // document.getElementsByClassName은 배열이 아니라 HtmlCollection을 리턴함
      // 아래 라인은 이를 배열로 인식하게 함.
      HTMLCollection.prototype.forEach = Array.prototype.forEach;
      document.getElementsByClassName("cr-dropzone-list").forEach(el => {
        const { length } = el.getElementsByClassName("cr-drop-list");
        if (length > 0) return;

        const dropEl = document.createElement("div");
        Object.assign(dropEl.style, {
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px"
        });
        dropEl.id = el.getAttribute("data-id");
        dropEl.classList.add("cr-drop-list");
        el.appendChild(dropEl);

        dropEl.addEventListener("mouseleave", async function(e) {
          e.target.style.border = null;
          e.target.style.background = null;
        });
      });
    },
    markDropzone(e) {
      if (e.target.classList.contains("cr-drop-list")) {
        const borderStyle = "3px solid #9E9E9E";
        const backgroundStyle = "rgba(1, 1, 1, 0.1)";

        e.target.style.borderTop = borderStyle;
        e.target.style.borderBottom = borderStyle;
        e.target.style.background = backgroundStyle;
      }
    },
    async mouseup(e) {
      const targetId = e.target.id;
      if (e.target.classList.contains("cr-drop-list")) {
        const items = this._selectedItems.map(
          item => this.getCardList.find(card => card.id === item.id) || item
        );
        if (items.length <= 0) return;

        const { contactGroupId: routeContactGroupId } = this.getCardListInfo;

        if (-2 == targetId) {
          // 중요 표시
          const importantStatus =
            items.length === items.filter(e => e.importantStatus === 1).length
              ? 0
              : 1;
          await this.updateImportantStatus({
            cardIdList: items.map(e => e.id),
            importantStatus: importantStatus,
            index: 0
          });
          this.reloadList(routeContactGroupId);
        } else {
          // 그룹 이동
          const { status } = await moveCard({
            ids: items.map(e => e.id),
            currentGroupId: routeContactGroupId,
            destinationGroupId: targetId
          });

          if (status === 200) {
            this.reloadList(routeContactGroupId);

            const { id } = this.detail;
            if (id) await this.getContactDetail(id);
          }
        }
      }

      // 드랍가능영역 제거
      const dropList = document.getElementsByClassName("cr-drop-list");
      while (dropList.length > 0) {
        dropList[0].parentNode.removeChild(dropList[0]);
      }
    },
    reloadList(routeContactGroupId) {
      this.$router.push({
        name: "contact_list",
        params: { contactGroupId: routeContactGroupId, page: this.getPage }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table::v-deep {
  min-width: 400px;
  border-radius: 0px;
  border-right: thin solid rgba(0, 0, 0, 0.08);

  .v-data-table__wrapper {
    height: calc(100% - 44px);

    table tbody tr {
      td {
        padding: 0 14px !important;
      }

      > td:first-child {
        width: 44px;
        min-width: 44px;
        max-width: 44px;
        padding-left: 16px !important;
      }

      td.importantStatus {
        width: 44px;
        min-width: 44px;
        max-width: 44px;
        padding: 0 4px !important;
      }

      &.cr-selected td {
        background-color: #efefef !important;
      }

      &.v-data-table__selected {
        background: transparent;
      }
    }
  }
}
</style>
