<template>
  <div class="cr-card-detail-wrapper mb-3">
    <div class="cr-card-detail pa-3">
      <!-- 툴바 -->
      <Header @save="save" />

      <div class="cr-card-detail-options" :class="{ mini: isMini }">
        <component
          ref="comp"
          v-for="(comp, idx) in components"
          :key="idx"
          :is="comp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Header from "./header";

export default {
  components: { Header },
  props: {
    components: {
      type: Object,
      default() {
        return {
          Name: () => import("./options/name"),
          Group: () => import("./options/group"),
          EmailList: () => import("./options/email"),
          Phone: () => import("./options/phone"),
          Company: () => import("./options/company"),
          Address: () => import("./options/address"),
          Homepage: () => import("./options/homepage"),
          Memo: () => import("./options/memo")
        };
      }
    }
  },
  computed: {
    ...mapGetters("cardDetail", ["detail", "isEdit"]),
    ...mapGetters("contactRoute", ["getPage", "getCardListInfo"]),
    isMini() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapMutations("cardDetail", ["SET_IS_EDIT", "SET_CARD_DETAIL"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("card", ["updateCard2"]),
    ...mapActions("cardDetail", ["getContactDetail"]),
    async save() {
      if (!this.isEdit) return this.SET_IS_EDIT(true);

      let data = { id: this.detail.id };
      this.$refs.comp.forEach(
        ref => (data = { ...data, ...(ref?.getData() ?? {}) })
      );

      // 업데이트 & 상세정보 조회
      await this.updateCard2(data);
      const detailData = await this.getContactDetail(data.id);

      const { contactGroupId: routeContactGroupId } = this.getCardListInfo;
      const { contactGroupList } = detailData;

      // 중요, 전체 연락처일 경우
      if ([-1, -2].includes(routeContactGroupId)) {
        return;
      }

      // 갱신된 주소록에 그룹이 없는 경우
      if (!contactGroupList) {
        this.reloadList(routeContactGroupId);
        return;
      }

      // 그룹은 있지만 선택된 그룹이 없는 경우
      const group = contactGroupList.find(e => e.id == routeContactGroupId);
      if (!group) {
        this.reloadList(routeContactGroupId);
      }
    },
    reloadList(routeContactGroupId) {
      this.SET_CARD_DETAIL({});
      this.$router.push({
        name: "contact_list",
        params: { contactGroupId: routeContactGroupId, page: this.getPage }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-card-detail-wrapper::v-deep {
  .cr-card-detail {
    border-radius: 8px;
    border: thin solid rgba(0, 0, 0, 0.08);
    background-color: white;

    .cr-card-detail-options {
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      margin: 0px -3px;
      &.mini {
        .cr-card-detail-option {
          flex: 1 1 100%;
        }
      }
      .cr-card-detail-option {
        flex: 1 1 40%;
        display: flex;
        align-items: center;
        min-height: 48px;
        margin: 3px 3px;
        padding: 8px;
        border-radius: 8px;
        //border: thin solid rgba(0, 0, 0, 0.08);
        background: #fff;
        user-select: text;

        > i.v-icon {
          margin-right: 16px;
        }

        .cr-edit-wrapper {
          width: calc(100% - 50px);
        }
      }
      .v-text-field fieldset {
        border-radius: 8px;
      }
    }
  }
}
</style>
