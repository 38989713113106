<template>
  <div class="cr-part pa-3" v-if="isShow">
    <div class="d-flex justify-space-between text-subtitle-2 font-weight-bold">
      <div class="mt-1 ml-1">
        {{ $t("mail.578") }}
      </div>
      <v-btn text depressed small color="primary" @click="goMail">
        {{ $t("common.더_보기") }}
      </v-btn>
    </div>

    <v-list>
      <v-list-item
        v-for="(item, idx) in mailList"
        :key="idx"
        @click="openMail(item.mailId)"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>{{
            item.readStatus ? "mdi-email-open-outline" : "mdi-email"
          }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <div class="d-flex" style="width:100%;">
            <!-- 제목 -->
            <div class="cr-subject" v-text="item.subject"></div>
            <!-- 시간 -->
            <div
              class="cr-date"
              v-text="getDateTime(item.receivedTimeMillis)"
            ></div>
          </div>
          <!-- 본문 미리보기 -->
          <div class="cr-preview">
            <v-chip
              v-if="nameFolder(item)"
              label
              small
              outlined
              class="mr-1 px-1 cr-name-folder"
            >
              {{ nameFolder(item) }}
            </v-chip>
            <span class="grey--text">{{ item.contentPreview }}</span>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getDateWithTime } from "@/commons/utils/moment";
import { getContactMailHistory } from "@/contact/api/detail.api";

export default {
  data() {
    return {
      mailList: []
    };
  },
  computed: {
    ...mapGetters("cardDetail", ["detail"]),
    ...mapGetters("folder", ["getFolder"]),
    isShow() {
      return this.mailList.length > 0;
    }
  },
  async mounted() {
    this.mailList = [];
    const { status, data } = await getContactMailHistory({
      id: this.detail.id
    });
    if (status !== 200) return;

    this.mailList = data.contents;
  },
  methods: {
    ...mapActions("group", ["searchMail"]),
    nameFolder(item) {
      const folder = this.getFolder(item.folderId);
      if (!folder) return false;

      return folder.nameFolder;
    },
    getDateTime(timeMillis) {
      return getDateWithTime(timeMillis);
    },
    openMail(mailId) {
      const list = { dir: "desc", sort: "receivedTimeMillis", search: {} };
      const view = { basedId: mailId, selectedDataId: mailId };

      window.open(
        `/#/mail/view/popup/${JSON.stringify({ list, view })}`,
        `mailview-${mailId}`,
        "width=1050,height=870,location=no,directories=no,resizable=no,status=no,toolbar=yes,menubar=no,scrollbars=yes"
      );
    },
    goMail() {
      const { emailList } = this.detail;
      if (emailList.length === 0) return;

      this.searchMail({ query: emailList[0] });
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-part {
  .v-list {
    margin: 0px -12px;
  }
  .cr-subject {
    font-size: 15px;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  .cr-date {
    font-size: 13px;
    min-width: 140px;
    text-align: right;
  }
  .cr-preview {
    font-size: 14px;
    margin-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
}
</style>
