<template>
  <div class="d-flex flex-grow-1 pt-15 mt-15 justify-center">
    <span class="subtitle-1 grey--text">
      선택된 연락처가 없습니다.
    </span>
  </div>
</template>

<script>
export default {};
</script>
