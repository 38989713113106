<template>
  <v-container class="pb-0">
    <!-- 이름 -->
    <v-row class="mb-2">
      <v-col cols="1">
        <v-icon v-text="'mdi-account-outline'" />
      </v-col>
      <v-col
        cols="11"
        class="pl-6"
        v-text="`${card.lastName}${card.firstName}`"
      />
    </v-row>

    <!-- 이메일 -->
    <v-row
      :class="`${index == card.emailList.length - 1 && 'mb-2'}`"
      v-for="(email, index) in card.emailList"
      :key="`email-${index}`"
    >
      <v-col cols="1">
        <v-icon v-text="index == 0 ? 'mdi-email-outline' : 'mdi-empty'" />
      </v-col>
      <v-col cols="11" class="pl-6" v-text="email" />
    </v-row>

    <!-- 전화번호 -->
    <v-row
      :class="`${index == card.telList.length - 1 && 'mb-2'}`"
      v-for="(tel, index) in card.telList"
      :key="`tel-${index}`"
    >
      <v-col cols="1">
        <v-icon v-text="index == 0 ? 'mdi-phone' : 'mdi-empty'" />
      </v-col>
      <v-col cols="2" class="pl-6" v-text="card.telTypeList[index]" />
      <v-col cols="9" class="pl-6" v-text="tel" />
    </v-row>

    <!-- 그룹 -->
    <v-row class="mb-2" v-if="card.contactGroupList.length > 0">
      <v-col cols="1">
        <v-icon v-text="'mdi-folder-outline'" />
      </v-col>
      <v-col cols="11" class="pl-6">
        <v-chip
          class="mr-2 mb-2"
          v-for="(group, index) in card.contactGroupList"
          v-text="group.groupName"
          :key="`group-${index}`"
        />
      </v-col>
    </v-row>

    <!-- 회사 | 부서 | 직책 -->
    <v-row
      class="mb-2"
      v-if="card.company != '' || card.organ != '' || card.position != ''"
    >
      <v-col cols="1">
        <v-icon v-text="'mdi-domain'" />
      </v-col>
      <v-col
        cols="11"
        class="pl-6"
        v-text="`${card.company} ${card.organ} ${card.position}`"
      />
    </v-row>

    <!-- 주소 타입 | 시/도 | 주소 | 우편번호 -->
    <v-row
      :class="`${index == card.addrTypeList.length - 1 && 'mb-2'}`"
      v-for="(addr, index) in card.addrTypeList"
      :key="`addr-${index}`"
    >
      <v-col cols="1">
        <v-icon v-text="index == 0 ? 'mdi-map-marker-outline' : 'mdi-empty'" />
      </v-col>
      <v-col cols="2" class="pl-6" v-text="addr" />
      <v-col
        cols="9"
        class="pl-6"
        v-text="
          `${card.localityList[index]} ${card.streetAddressList[index]} (우)${card.postList[index]}`
        "
      />
    </v-row>

    <!-- 홈페이지 -->
    <v-row
      :class="`${index == card.urlList.length - 1 && 'mb-2'}`"
      v-for="(url, index) in card.urlList"
      :key="`url-${index}`"
    >
      <v-col cols="1">
        <v-icon v-text="index == 0 ? 'mdi-web' : 'mdi-empty'" />
      </v-col>
      <v-col cols="11" class="pl-6" v-text="url" />
    </v-row>

    <!-- 메모 -->
    <v-row class="mb-2" v-if="card.memo && card.memo.length > 0">
      <v-col cols="1">
        <v-icon v-text="'mdi-comment-text-outline'" />
      </v-col>
      <v-col cols="11" class="pl-6" v-text="card.memo" />
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인버튼"
    }
  },
  data: () => ({
    card: {}
  }),
  created() {
    this.card = this.getParams.card;
  },
  watch: {
    confirm: function(next) {
      if (next) {
        this.SET_DIALOG({
          title: i18n.t("contact.11"),
          type: "updateCard",
          showExpand: true,
          confirmBtn: {
            show: true,
            text: i18n.t("common.확인")
          }
        });
      }
    }
  },
  methods: {
    ...mapMutations("contactDialog", ["SET_DIALOG"]),
    ...mapActions("card", ["addCard"]),
    ...mapActions("group", ["getPrivateContactGroupList"]),
    async getContactGroupList() {
      this.contactGroupList = await this.getPrivateContactGroupList();
      this.$refs["contactGroupList"].$refs.menu.updateDimensions();
    }
  },
  computed: {
    ...mapGetters("contactDialog", ["getParams"])
  }
};
</script>
