<template>
  <div :class="`cr-contact-main ${getShowSetting ? 'cr-setting' : ''}`">
    <ContactConfig v-if="$route.name.split('_')[1] === 'config'" />
    <CardList v-else />
    <ConfigDrawer />
    <Dialog></Dialog>

    <ContactTutorial />
  </div>
</template>

<style lang="scss" scoped>
.cr-contact-main {
  height: 100%;
  // 환경설정 drawer
  .cr-wrapper {
    position: relative;
    height: 100%;
  }

  &.cr-setting {
    padding-right: 320px;
  }
}
</style>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import ContactTutorial from "@/commons/views/tutorial/ContactTutorial";

import CardList from "./components/list/CardList";
import ContactConfig from "./components/ContactConfig";
import ConfigDrawer from "./components/ConfigDrawer";
import Dialog from "@/contact/views/components/Dialog";

export default {
  created() {
    // 환경설정 닫기
    this.SET_SHOW_SETTING(false);
    this.initData();
  },
  components: {
    ContactTutorial,
    CardList,
    ContactConfig,
    ConfigDrawer,
    Dialog
  },
  computed: {
    ...mapGetters("contactLayout", ["getShowSetting"])
  },
  methods: {
    ...mapMutations("contactLayout", ["SET_SHOW_SETTING"]),
    ...mapActions("contactRoute", ["initData"])
  }
};
</script>
