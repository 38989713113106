<template>
  <div class="cr-contact-config-wrapper">
    <v-tabs v-model="selectedTab">
      <v-tab
        v-for="tab in tabs"
        v-show="tab.use"
        :key="tab.tabId"
        @click="() => routeConfigTab({ tabId: tab.tabId })"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item :transition="false">
        <ContactViewConfig ref="view_config"></ContactViewConfig>
      </v-tab-item>
      <v-tab-item :transition="false">
        <ContactImportConfig ref="import_setting"></ContactImportConfig>
      </v-tab-item>
      <v-tab-item :transition="false">
        <ContactExportConfig ref="export_setting"></ContactExportConfig>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapActions, mapGetters } from "vuex";
import ContactViewConfig from "./config/ContactViewConfig";
import ContactImportConfig from "./config/ContactImportConfig";
import ContactExportConfig from "./config/ContactExportConfig";

export default {
  components: { ContactViewConfig, ContactImportConfig, ContactExportConfig },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        { tabId: "list", name: i18n.t("contact.9"), use: true },
        { tabId: "import", name: i18n.t("contact.10"), use: true },
        { tabId: "export", name: i18n.t("contact.90"), use: true }
      ]
    };
  },
  mounted() {
    this.selectTab(this.getConfigTabType);
  },
  watch: {
    getConfigTabType(type) {
      this.selectTab(type);
    },
    // 탭 이동시 초기화 이전 탭 내용 초기화
    selectedTab(next, prev) {
      this.reset(prev);
    }
  },
  computed: {
    ...mapGetters("contactRoute", ["getConfigTabType"])
  },
  methods: {
    ...mapActions("contactRoute", ["routeConfigTab"]),
    selectTab(type) {
      const index = this.tabs.findIndex(t => t.tabId === type);
      if (index !== -1) {
        this.selectedTab = index;
      }
    },
    reset(tab) {
      switch (tab) {
        // 주소록 보기 설정
        case 0:
          if (this.$refs.view_config) this.$refs.view_config.reset();
          break;
        // 주소록 불러오기
        case 1:
          if (this.$refs.import_setting) this.$refs.import_setting.reset();
          break;
        // 주소록 내보내기
        case 2:
          if (this.$refs.export_setting) this.$refs.export_setting.reset();
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-contact-config-wrapper {
  position: relative;
  height: 100%;
  .v-window ::v-deep {
    height: calc(100% - 48px);
    overflow-y: auto;
    .v-window__container {
      .v-window-item {
        .cr-config-wrapper {
          max-width: 1029px;
        }
      }
    }
  }
}
</style>
