var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('v-data-table',{directives:[{name:"drag-and-drop",rawName:"v-drag-and-drop",value:({
      /**
       * drag&drop directive
       *
       * DRAG_COMP    - directive를 사용하는 컴포넌트 타이틀
       * genHelper    - helper 생성 함수 / Argument(event, setDragInfo)) / return element
       * dragstart    - dragstart / Argument(event, setDragInfo))
       * genDropzone  - 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)
       * markDropzone - 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)
       * mouseup      - drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))
       */
      DRAG_COMP: 'list',
      genHelper: _vm.genHelper,
      dragstart: _vm.dragstart,
      genDropzone: _vm.genDropzone,
      markDropzone: _vm.markDropzone,
      mouseup: _vm.mouseup
    }),expression:"{\n      /**\n       * drag&drop directive\n       *\n       * DRAG_COMP    - directive를 사용하는 컴포넌트 타이틀\n       * genHelper    - helper 생성 함수 / Argument(event, setDragInfo)) / return element\n       * dragstart    - dragstart / Argument(event, setDragInfo))\n       * genDropzone  - 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)\n       * markDropzone - 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)\n       * mouseup      - drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))\n       */\n      DRAG_COMP: 'list',\n      genHelper,\n      dragstart,\n      genDropzone,\n      markDropzone,\n      mouseup\n    }"}],attrs:{"show-select":"","fixed-header":"","hide-default-header":"","hide-default-footer":"","item-key":"id","item-class":_vm.itemClass,"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.getCardList,"items-per-page":_vm.getPageSize,"server-items-length":_vm.getTotalCount,"draggable":"true"},on:{"page-count":function($event){_vm.pageCount = $event},"item-selected":_vm.itemSelected,"click:row":function (r) { return _vm.getContactDetail(r.id); }},scopedSlots:_vm._u([{key:"item.importantStatus",fn:function(ref){
    var item = ref.item;
return [_c('ImportantBtn',{attrs:{"id":item.id}})]}},{key:"item.userName",fn:function(ref){
    var item = ref.item;
return [_c('ListItem',_vm._b({attrs:{"item":item}},'ListItem',_vm.$props,false))]}},{key:"footer",fn:function(){return [(_vm.pageCount > 0)?_c('Pagination',{attrs:{"type":"list","page":_vm.page,"pageSize":_vm.getPageSize,"blockSize":3,"totalElements":_vm.getTotalCount},on:{"update:page":function($event){_vm.page=$event},"pageClick":function (page) { return _vm.pageClick(page); }}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm._selectedItems),callback:function ($$v) {_vm._selectedItems=$$v},expression:"_selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }