<template>
  <div class="wrap d-flex flex-grow-1">
    <UnifiedView v-if="!loading && !isEmpty" />
    <EmptyMessage v-if="isEmpty" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UnifiedView from "./unified";
import EmptyMessage from "./EmptyMessage.vue";

export default {
  components: { UnifiedView, EmptyMessage },
  computed: {
    ...mapGetters("cardDetail", ["isEmpty", "loading"])
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  overflow-y: auto;
  background-color: #f9f9f9;
}
</style>
