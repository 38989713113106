<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          required
          autofocus
          ref="newGroupName"
          :label="$t('contact.34')"
          v-model="groupName"
          autocomplete="off"
          @keydown.enter="$emit('update:confirm', true)"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import { privateContactGroupType } from "@/contact/constant/contactGroupType";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인버튼"
    }
  },
  watch: {
    confirm: function(next) {
      if (next) {
        this.addContactGroupFunc();
      }
    },
    contactGroupType: function() {
      return this.$refs.newGroupName.focus();
    }
  },
  data: () => ({
    groupName: "",
    contactGroupType: privateContactGroupType.PRIVATE,
    privateContactGroupType
  }),
  methods: {
    ...mapMutations("contactDialog", ["CLOSE_DIALOG"]),
    ...mapActions("group", ["addContactGroup"]),
    ...mapActions("contactDialog", ["confirmCallback"]),
    async addContactGroupFunc() {
      const { groupName, contactGroupType } = this;

      const { parentContactGroup } = this.getParams;
      this.groupName = groupName.trim();

      if (!this.groupName) {
        this.$emit("update:confirm", false);
        return this.$refs.newGroupName.focus();
      }

      await this.addContactGroup({
        groupName: this.groupName,
        contactGroupType,
        parentId: parentContactGroup ? parentContactGroup.id : 0
      });
      await this.confirmCallback();
      this.CLOSE_DIALOG();
    }
  },
  computed: {
    ...mapGetters("contactDialog", ["getParams"])
  }
};
</script>
