<template>
  <div>
    <div class="d-flex align-center mb-2">
      <v-text-field
        ref="newGroupName"
        outlined
        dense
        hide-details
        v-model="groupName"
        @keydown.enter="addContactGroupFunc"
      ></v-text-field>
      <v-btn
        depressed
        height="40"
        color="primary"
        tile
        @click="addContactGroupFunc"
      >
        추가
      </v-btn>
    </div>

    <v-treeview
      v-model="selection"
      :items="getPrivateContactGroupTree"
      selection-type="independent"
      selectable
      return-object
      open-all
      hoverable
      dense
      :open="open"
    >
      <template slot="label" slot-scope="{ item }">
        <div class="cr-tree-node" @click="selectItem(item)">
          {{ item.groupName }}
        </div>
      </template>
    </v-treeview>
  </div>
</template>

<style scoped>
.v-treeview >>> .notranslate.v-treeview-node__toggle {
  pointer-events: none;
}

.cr-tree-node {
  cursor: pointer;
}
</style>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  created() {
    const contactGroupIdMap = {};
    const contactGroupIdList = [];
    this.getParams.selectedItems.map(card => {
      card.contactGroupIdList.map(id => {
        contactGroupIdMap[id] = contactGroupIdMap[id]
          ? (contactGroupIdMap[id] = contactGroupIdMap[id] + 1)
          : 1;
      });
    });
    for (const [key, value] of Object.entries(contactGroupIdMap)) {
      if (value == this.getParams.selectedItems.length) {
        contactGroupIdList.push(Number(key));
      }
    }

    contactGroupIdList.map(id => {
      this.selection.push(this.getTreeContactGroup(id));
    });
  },
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인버튼"
    }
  },
  computed: {
    ...mapGetters("group", [
      "getPrivateContactGroupTree",
      "getTreeContactGroup"
    ]),
    ...mapGetters("contactDialog", ["getParams"])
  },
  watch: {
    confirm: function(next) {
      if (next) {
        this.copyContactGroup();
      }
    }
  },
  data: () => ({
    groupName: "",
    open: [],
    selection: [],
    contactGroupList: []
  }),
  methods: {
    ...mapActions("card", ["updateContactRelation"]),
    ...mapMutations("contactDialog", ["CLOSE_DIALOG"]),
    ...mapActions("contactDialog", ["confirmCallback"]),
    ...mapActions("group", ["addContactGroup"]),

    selectItem(item) {
      let index = -1;
      this.selection.forEach((contactGroup, idx) => {
        if (contactGroup.id === item.id) {
          index = idx;
        }
      });
      if (index < 0) {
        this.selection.push(item);
      } else {
        this.selection.splice(index, 1);
      }
    },

    // 연락처 이동/복사
    copyContactGroup() {
      const selectedContactGroupIdList = this.selection.map(({ id }) => id);
      const cardIdList = this.getParams.selectedItems.map(({ id }) => id);
      const contactGroupIdList =
        cardIdList.length > 1
          ? this.getParams.selectedItems.map(({ contactGroupIdList }) => [
              ...new Set([...contactGroupIdList, ...selectedContactGroupIdList])
            ])
          : [selectedContactGroupIdList];

      if (!this.getParams?.isDetail) {
        this.updateContactRelation({ cardIdList, contactGroupIdList });
      }

      this.confirmCallback(this.selection);
      this.CLOSE_DIALOG();
    },

    // 새로운 그룹 추가
    async addContactGroupFunc() {
      const { groupName } = this;
      this.groupName = groupName.trim();
      if (!this.groupName) {
        this.$emit("update:confirm", false);
        return this.$refs.newGroupName.focus();
      }

      await this.addContactGroup({
        parentId: 0,
        groupName: this.groupName,
        contactGroupType: "PRIVATE"
      });

      this.groupName = "";
      setTimeout(() => {
        this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight;
      }, 0);
    }
  }
};
</script>
