<template>
  <v-btn icon :color="color" @click.stop="clickImportantStatus">
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    id: { type: Number, default: 0 }
  },
  computed: {
    ...mapGetters("card", ["getCardList"]),
    index() {
      return this.getCardList.findIndex(c => c.id == this.id);
    },
    card() {
      return this.getCardList[this.index];
    },
    color() {
      const { importantStatus } = this.card;
      if (!importantStatus) return "";
      return "yellow darken-1";
    },
    icon() {
      const { importantStatus } = this.card;
      if (importantStatus) return "mdi-star";
      return "mdi-star-outline";
    }
  },
  methods: {
    ...mapActions("card", ["updateImportantStatus"]),
    clickImportantStatus() {
      const { id, importantStatus } = this.card;
      this.updateImportantStatus({
        cardIdList: [id],
        importantStatus: importantStatus ? 0 : 1,
        index: this.index
      });
    }
  }
};
</script>
