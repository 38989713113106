<template>
  <div class="cr-wrapper">
    <Toolbar
      :selectedItems="selectedItems"
      @openCardUpdateDialog="openCardUpdateDialog"
      @deleteCardList="deleteCardList"
      @print="print"
      @merge="merge"
      @openCopyCardDialog="openCopyCardDialog"
      @clearSelectedItems="clearSelectedItems"
      @openMailWritePopup="openMailWritePopup"
      @copyCardToCustomerDialog="openCopyCardToCustomerDialog"
      :showConsonant.sync="showConsonant"
      @toggleCheckbox="toggleCheckbox"
    />
    <v-divider />

    <!-- 목록 / 보기 -->
    <div
      class="cr-container ch-2"
      :class="`cr-container-${this.$vuetify.breakpoint.name}`"
    >
      <Split
        :selectedItems.sync="selectedItems"
        @clearSelectedItems="clearSelectedItems"
        @selectedItemCancel="selectedItemCancel"
      />
    </div>

    <!-- 초성 필터 -->
    <v-card
      v-if="showConsonant"
      class="cr-consonant"
      v-click-outside="() => (this.showConsonant = false)"
    >
      <v-list dense mandatory class="px-2">
        <v-btn-toggle
          v-model="consonantSelected"
          mandatory
          tile
          group
          color="deep"
        >
          <v-btn
            v-for="consonant in consonantList"
            :key="consonant.label"
            small
            text
            class="cr-consonant-btn"
            @click="() => clickConsonant(consonant)"
          >
            {{ consonant.label }}
          </v-btn>
        </v-btn-toggle>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Toolbar from "./Toolbar";
import Split from "./split";
import { virtualContactGroupConverter } from "@/contact/constant/contactGroupType";

export default {
  components: { Toolbar, Split },
  created() {
    // url로 들어왔을때 page값 설정
    this.page = this.getPage;
  },
  computed: {
    ...mapGetters("card", [
      "getHeaders",
      "getCardList",
      "getTotalCount",
      "isLoading"
    ]),
    ...mapGetters("contactRoute", ["getCardListInfo", "getPage"]),

    ...mapGetters("contactConfig", [
      "getBlockSize",
      "getPageSize",
      "isUseSelectBox"
    ]),
    // drag&drop
    ...mapGetters("dragDrop", ["dragElement", "dragElementIndex"]),
    ...mapGetters("selectBox", ["isShowBox", "getSelectBoxItems"]),
    indeterminate: function() {
      const { length: selectedLength } = this.selectedItems;
      const { length: cardLength } = this.getCardList;

      return selectedLength > 0 && selectedLength < cardLength;
    }
  },
  watch: {
    getCardList() {
      this.selectedItems = [];

      if (!this.isUseSelectBox) return;
      // selectBox사용 시 selectBox에 있는 item 현재 컴포넌트 selectItems에 추가
      this.getSelectBoxItems.forEach(boxItem => {
        const index = this.getCardList.findIndex(
          listItem => listItem.id == boxItem.id
        );
        if (index >= 0) this.selectedItems.push(boxItem);
      });
    },
    getPage(page) {
      this.page = page;
    },
    getCardListInfo(nextCardListInfo, prevCardListInfo) {
      // 그룹변경 때 parameter들(정렬, 초성) 초기화
      // 그룹에서 페이지이동이나, 정렬, 초성등 변경일 경우는 초기화 진행 x
      if (nextCardListInfo.contactGroupId == prevCardListInfo.contactGroupId)
        return;
      const { contactGroupId, sort, dir } = nextCardListInfo;
      this.isNew = contactGroupId == "new";
      this.options.sortBy[0] = sort || "userName";
      this.options.sortDesc[0] = dir == "desc";
      this.consonantSelected = undefined;
    },
    selectedItems: function(nextSelectedItems) {
      if (nextSelectedItems.length > 0) {
        if (this.isUseSelectBox) this.SET_SHOW(true);
        if (nextSelectedItems.length == this.getCardList.length)
          this.toggleCheck = true;

        const selectedItems = nextSelectedItems
          .map(({ id, userName }) => ({
            id,
            userName
          }))
          .sort((p, n) => p.userName.localeCompare(n.userName));
        this.id = selectedItems[0].id;
      } else {
        this.id = null;
        this.toggleCheck = false;
      }
    }
  },
  methods: {
    ...mapMutations("contactDialog", ["SET_DIALOG"]),
    ...mapMutations("selectBox", [
      "SET_SHOW",
      "SET_SELECT_ITEM",
      "SET_SELECT_ITEM_All",
      "RESET_SELECT_BOX"
    ]),
    ...mapActions("mailRoute", ["routeMailWrite"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    ...mapActions("card", [
      "getContactDetail",
      "delete",
      "updateImportantStatus",
      "getContactDetailList"
    ]),
    ...mapActions("group", ["getPrivateContactGroupTree"]),
    // drag&drop
    ...mapActions("dragDrop", ["setDragElementCount"]),
    // selectBox에 추가
    putSelectBox(selected) {
      // selectBox사용 안할 시 return
      if (!this.isUseSelectBox) return;
      this.SET_SELECT_ITEM(selected.item);
    },
    // item 선택 해제(selectBox에서 사용)
    selectedItemCancel(item) {
      let index = this.selectedItems.findIndex(
        selectedItem => selectedItem.id == item.id
      );

      if (index >= 0) {
        this.selectedItems.splice(index, 1);
      }
    },
    beforeDragstart() {
      const dragItem = this.getCardList[this.dragElementIndex];
      const cardIdList = this.selectedItems.map(({ id }) => id);
      if (
        this.selectedItems.length <= 0 ||
        cardIdList.indexOf(dragItem.id) < 0
      ) {
        this.selectedItems.push(dragItem);
        this.setDragElementCount(this.selectedItems.length);
      }
    },
    mouseup() {},
    // file
    drop() {},

    toggleCheckbox(checked) {
      if (checked) {
        this.selectedItems = this.getCardList;
      } else {
        this.selectedItems = [];
      }

      // selectBox사용시 selectBox에 체크된item 추가
      if (!this.isUseSelectBox) return;
      this.SET_SELECT_ITEM_All({ checked, list: this.getCardList });
    },

    getHeaderClass(header) {
      const { sortBy, sortDesc } = this.options;
      let cls = header.class ? header.class : "";

      if (header.sortable) {
        cls += " sortable";
        if (sortBy[0] == header.value) {
          cls += " active";
        }
        if (sortDesc[0]) {
          cls += " desc";
        } else {
          cls += " asc";
        }
      }
      return cls;
    },

    clickHeader(header) {
      const { sortBy, sortDesc } = this.options;
      const { actionObj = {} } = this.getCardListInfo;

      if (sortBy[0] == header.value) {
        this.options.sortDesc[0] = !sortDesc[0];
      } else {
        this.options.sortBy[0] = header.value;
        this.options.sortDesc[0] = false;
      }
      this.$forceUpdate();
      this.pushRoute({ consonant: actionObj.consonant });
    },

    pushRoute({ page, consonant }) {
      const {
        contactGroupId: id,
        page: infoPage,
        actionObj = {}
      } = this.getCardListInfo;
      let contactGroupId = id;
      const { sortBy, sortDesc } = this.options;
      let newActionObj = { ...actionObj };

      if (sortBy[0]) {
        newActionObj.sort = sortBy[0];
        newActionObj.dir = sortDesc[0] ? "desc" : "asc";
      }

      if (consonant) {
        newActionObj.consonant = consonant;
      } else if (this.consonantSelected > 0 && consonant) {
        newActionObj.consonant = this.consonantList[
          this.consonantSelected
        ].value;
      } else {
        if (!consonant) {
          delete newActionObj.consonant;
        }
      }

      this.$router.push({
        name: "contact_list_action",
        params: {
          contactGroupId: virtualContactGroupConverter(contactGroupId),
          page: page || infoPage,
          actionObj: JSON.stringify(newActionObj)
        }
      });
    },

    pageClick(page) {
      // routing시 전달되는 parameter가 있는 지 체크
      const { params } = this.$route;
      const { contactGroupId } = this.getCardListInfo;

      const { actionObj } = params;
      // 페이지 정보 를 가지고 라우팅 진행
      this.$router.push({
        name: actionObj ? "contact_list_action" : "contact_list",
        params: {
          contactGroupId: virtualContactGroupConverter(contactGroupId),
          page,
          actionObj
        }
      });
    },

    clickConsonant(item) {
      this.page = 1;
      this.pushRoute({ page: 1, consonant: item.value });
    },

    clickRow(item, row) {
      row.select(!row.isSelected);
    },

    clickImportantStatus(e, item, index) {
      e.stopPropagation();
      this.updateImportantStatus({
        cardIdList: [item.id],
        importantStatus: item.importantStatus ? 0 : 1,
        index
      });
    },

    // Dialog 닫기
    closeDialog() {
      this.dialog.showDialog = false;
    },

    // 연락처 보기 Dialog 열기
    async openCardViewDialog(e, id) {
      e.stopPropagation();

      this.SET_DIALOG({
        title: i18n.t("contact.36"),
        type: "viewCard",
        params: {
          card: await this.getContactDetail(id)
        },
        confirmBtn: {
          show: true,
          text: i18n.t("common.수정")
        }
      });
    },

    // 연락처 수정 Dialog 열기
    async openCardUpdateDialog(e, id) {
      e.stopPropagation();
      this.SET_DIALOG({
        title: i18n.t("contact.11"),
        type: "updateCard",
        showExpand: true,
        params: {
          card: await this.getContactDetail(id)
        }
      });
    },

    // 개인편지함 마우스 우클릭
    openContextMenu(e, row) {
      e.preventDefault();
      this.closePositioningMenu();

      const { item, index } = row;

      // selectBox사용에 따른 selectItems설정
      const selectedItems = this.isUseSelectBox
        ? this.getSelectBoxItems
        : this.selectedItems;

      const selectedItemCount = selectedItems.length;
      let title =
        selectedItemCount == 0
          ? item.userName
          : i18n.t("contact.37", { value: selectedItemCount });

      const itemList = [];

      if (selectedItemCount > 0) {
        const cardIdList = selectedItems.map(({ id }) => id);
        itemList.push({
          label: i18n.t("contact.38"),
          func: () => {
            this.updateImportantStatus({ cardIdList, importantStatus: 1 });
            this.clearSelectedItems();
          }
        });
        itemList.push({
          label: i18n.t("contact.39"),
          func: () => {
            this.updateImportantStatus({ cardIdList, importantStatus: 0 });
            this.clearSelectedItems();
          }
        });
      } else {
        itemList.push({
          label: item.importantStatus
            ? i18n.t("contact.39")
            : i18n.t("contact.38"),
          func: () => {
            this.clickImportantStatus(e, item, index);
          }
        });
      }

      if (selectedItemCount <= 1) {
        itemList.push({
          label: i18n.t("contact.36"),
          func: () => {
            this.openCardViewDialog(e, item.id);
          }
        });

        itemList.push({
          label: i18n.t("contact.11"),
          func: () => {
            this.openCardUpdateDialog(e, item.id);
          }
        });
      }

      itemList.push({
        label: i18n.t("contact.40"),
        func: () => {
          this.deleteCardList(e, item);
        }
      });

      this.positioningMenu({
        title: title,
        x: e.clientX,
        y: e.clientY,
        itemList
      });
    },
    itemClass(item) {
      let className = "item-row";
      if (item.id % 2 == 0) {
        className += " cr-drop-item-row";
      }
      return className;
    },
    deleteCardList(e, item) {
      let message;
      const cardIdList = [];

      // selectBox사용에 따른 selectItems설정
      const selectedItems = this.isUseSelectBox
        ? this.getSelectBoxItems
        : this.selectedItems;

      if (selectedItems.length > 0) {
        message = i18n.t("contact.41");
        selectedItems.map(({ id }) => cardIdList.push(id));
      } else {
        message = i18n.t("contact.42");
        cardIdList.push(item.id);
      }
      this.confirm({
        message,
        callback: () => {
          this.delete(cardIdList);
          this.clearSelectedItems();
        },
        showCloseBtn: true
      });
    },
    clearSelectedItems() {
      this.selectedItems = [];

      // selectBox 사용시 초기화
      if (!this.isUseSelectBox) return;
      this.RESET_SELECT_BOX();
    },

    // 메일 쓰기 팝업 호출
    async openMailWritePopup(list) {
      this.routeMailWrite({
        t: 7,
        is: list.map(({ id }) => id)
      });
    },

    // 인쇄
    print() {
      const headers = [];
      for (const header of this.getHeaders) {
        headers.push({ text: header.text, value: header.value });
      }
      const { contactGroupId: id, actionObj } = this.getCardListInfo;
      const { sort, dir, consonant, pageSize } = actionObj;

      let contactGroupId = id;
      switch (id) {
        case -1:
          contactGroupId = "all";
          break;
        case -2:
          contactGroupId = "important";
          break;
        case -3:
          contactGroupId = "new";
          break;
      }

      window.open(
        `/#/contact/print/${contactGroupId}/1/${JSON.stringify({
          headers,
          sort,
          dir,
          consonant,
          pageSize
        })}`,
        "cardprint",
        "width=900,height=600,location=no,directories=no,resizable=no,status=no,toolbar=yes,menubar=no,scrollbars=yes"
      );
    },
    // 합치기
    async merge() {
      // selectBox사용에 따른 selectItems설정
      const selectedItems = this.isUseSelectBox
        ? this.getSelectBoxItems
        : this.selectedItems;

      const cardIdList = selectedItems.map(({ id }) => id);
      const mergeCard = await this.getContactDetailList(cardIdList);
      mergeCard.id = this.id;

      this.SET_DIALOG({
        title: i18n.t("contact.43"),
        type: "mergeCard",
        params: {
          mergeCard
        },
        showExpand: true,
        confirmCallbackFunc: () => {
          this.clearSelectedItems();
        }
      });
    },
    // 연락처 복사 Dialog 열기
    async openCopyCardDialog() {
      const result = await this.getPrivateContactGroupTree();
      if (result) {
        this.SET_DIALOG({
          title: i18n.t("contact.17"),
          type: "copyCard",
          params: {
            selectedItems: this.isUseSelectBox
              ? this.getSelectBoxItems
              : this.selectedItems
          },
          confirmCallbackFunc: () => {
            this.clearSelectedItems();
          }
        });
      }
    },
    openCopyCardToCustomerDialog() {
      const selectedItems = this.isUseSelectBox
        ? this.getSelectBoxItems
        : this.selectedItems;
      const cardIdList = selectedItems.map(({ id }) => id);
      this.SET_DIALOG({
        title: i18n.t("contact.고객주소록_복사"),
        type: "copyCardToCustomer",
        //showExpand: ,
        params: {
          selectedCardIdList: cardIdList
        }
      });
    }
  },
  data() {
    return {
      selectedItems: [],
      page: 1,
      pageCount: 0,
      toggleCheck: false,
      loading: true,
      options: {
        mustSort: true,
        sortBy: ["userName"],
        sortDesc: [false]
      },
      consonantSelected: undefined,
      consonantList: [
        { label: "전체" },
        { label: "가", value: "ㄱ" },
        { label: "나", value: "ㄴ" },
        { label: "다", value: "ㄷ" },
        { label: "라", value: "ㄹ" },
        { label: "마", value: "ㅁ" },
        { label: "바", value: "ㅂ" },
        { label: "사", value: "ㅅ" },
        { label: "아", value: "ㅇ" },
        { label: "자", value: "ㅈ" },
        { label: "차", value: "ㅊ" },
        { label: "카", value: "ㅋ" },
        { label: "타", value: "ㅌ" },
        { label: "파", value: "ㅍ" },
        { label: "하", value: "ㅎ" },
        { label: "A-Z", value: "A" },
        { label: "0-9", value: "0" }
      ],
      selectedCount: 0,
      sortBy: "userName",
      sortDesc: false,
      dialog: {
        card: {},
        showDialog: false,
        parentFolder: null,
        folder: null
      },
      isNew: false,
      showConsonant: false,
      helperLabel: "그룹에 연락처 $n개 이동"
    };
  }
};
</script>

<style lang="scss" scoped>
/* 데이타 테이블 */
.cr-container {
  position: absolute;
  top: 39px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  .v-data-table ::v-deep {
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0px;
    /* content body */
    .v-data-table__wrapper {
      height: calc(100% - 54px);
      overflow-y: auto;
      overflow-x: hidden;
    }
    .cr-use-selectbox {
      height: calc(100% - 144px);
    }
    /* row style */
    .v-input--selection-controls__input {
      margin: 0px;
    }
    .v-data-table-header {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      > tr > th > .helper {
        font-size: 18px;
      }
    }

    .item-row {
      > .text-start {
        max-width: 1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > .text-start.importantStatus {
        overflow: inherit;
      }
    }
    .min-width {
      min-width: 170px;
    }
  }
}
/* 초성 검색 */
.cr-consonant ::v-deep {
  z-index: 2;
  position: absolute;
  right: 13px;
  top: 39px;
  .v-item-group {
    z-index: 2;
    padding: 0px;
    background-color: #fff;
  }
  .v-item-group > .cr-consonant-btn {
    min-width: 0px;
    padding: 5px;
    margin: 0px;
    height: inherit;
  }
}
</style>
