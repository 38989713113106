<template>
  <div class="pa-2 pb-0" style="max-width: 1029px;">
    <v-row class="no-gutters my-2 px-3">
      <div class="cr-config-title" v-text="$t('contact.105')"></div>
    </v-row>
    <v-divider />

    <!-- 내보낼 항목 선택 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 cr-config-title d-flex align-center grey--text text--darken-2"
        cols="12"
        sm="4"
        v-text="$t('contact.91')"
      />
      <v-col cols="12" sm="8" class="pl-3">
        <v-checkbox
          hide-details
          class="ma-0 pa-0"
          :label="$t('contact.전체')"
          v-model="isAllField"
          @click="toggleAllField"
        />
        <div class="d-flex flex-wrap">
          <div
            class="d-inline-block"
            style="width:25%"
            v-for="(field, index) in fieldList"
            :key="index"
          >
            <v-checkbox
              hide-details
              class="ma-0 pa-0"
              :label="fieldText(field)"
              :disabled="field.disabled"
              v-model="field.value"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <!-- 대상 선택 -->
    <v-row class="ma-0">
      <v-col
        class="pb-0 pb-sm-3 cr-config-title d-flex align-center grey--text text--darken-2"
        cols="12"
        sm="4"
        v-text="$t('contact.92')"
      />
      <v-col cols="12" sm="8" class="pl-3 cr-swtich-wrapper">
        <div class="d-flex">
          <v-checkbox
            hide-details
            class="ma-0 pa-0 d-inline-block"
            :label="$t('contact.전체')"
            v-model="isAllGroup"
            @click="toggleAllGroup"
          />
          <span
            class="d-inline-block ml-2 caption align-self-center"
            v-text="$t('contact.103')"
          />
        </div>
        <div class="d-flex flex-wrap">
          <div
            class="d-inline-block"
            style="width: 25%"
            v-for="(field, index) in groupList"
            :key="index"
          >
            <v-checkbox
              hide-details
              class="ma-0 pa-0"
              :label="field.key"
              :disabled="field.disabled"
              v-model="field.value"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider />

    <v-row>
      <v-col cols="12" class="my-10 d-flex align-center justify-center">
        <v-btn
          class="font-weight-bold"
          color="primary"
          depressed
          :disabled="!enableSave || delay > 0"
          @click="saveContact"
        >
          <span v-if="delay === 0" v-text="$t('contact.저장하기')" />
          <span v-else class="ml-1">{{ delay }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapActions } from "vuex";
import { getPrivateContactGroupList } from "@/contact/api/group.api";

export default {
  data: () => ({
    delay: 0,

    fields: [
      "name",
      "email",
      "tel",
      "groupName",
      "company",
      "organ",
      "position",
      "url",
      "memo"
    ],

    isAllField: false,
    fieldList: [],

    isAllGroup: true,
    groupList: [],

    enableSave: false
  }),
  async mounted() {
    const defaultField = ["name", "email"];
    this.fieldList = [];
    this.fields.forEach(field => {
      this.fieldList.push({
        key: field,
        value: defaultField.includes(field),
        disabled: defaultField.includes(field)
      });
    });

    // 그룹
    const { data = [] } = await getPrivateContactGroupList();
    this.groupList = [];
    data.forEach(group => {
      this.groupList.push({
        key: group.groupName,
        value: true,
        id: group.id
      });
    });
  },
  watch: {
    fieldList: {
      deep: true,
      handler() {
        const len = this.fieldList.filter(e => e.value).length;
        if (this.fieldList.length === len) {
          this.isAllField = true;
          return;
        }
        this.isAllField = false;
      }
    },
    groupList: {
      deep: true,
      handler(groupList) {
        const len = groupList.filter(e => e.value).length;
        this.enableSave = this.isAllGroup || len > 0;

        if (this.groupList.length === len) return;
        this.isAllGroup = false;
      }
    }
  },
  computed: {},
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    reset() {},
    fieldText(field) {
      switch (field.key) {
        case "name":
          return i18n.t("contact.93");
        case "email":
          return i18n.t("contact.94");
        case "tel":
          return i18n.t("contact.95");
        case "groupName":
          return i18n.t("contact.96");
        case "company":
          return i18n.t("contact.97");
        case "organ":
          return i18n.t("contact.98");
        case "position":
          return i18n.t("contact.99");
        case "url":
          return i18n.t("contact.101");
        case "memo":
          return i18n.t("contact.102");
        default:
          return "";
      }
    },
    // 전체 선택
    toggleAllField() {
      this.fieldList.forEach(field => {
        if (!field.disabled) {
          field.value = this.isAllField;
        }
      });
    },
    toggleAllGroup() {
      this.groupList.forEach(group => {
        if (!group.disabled) {
          group.value = this.isAllGroup;
        }
      });
    },
    countDown() {
      setTimeout(() => {
        this.delay--;
        if (this.delay > 0) this.countDown();
      }, 1000);
    },
    saveContact() {
      this.delay = 5;
      this.countDown();

      this.openSnackbar({
        message: i18n.t("contact.106"),
        setTop: true
      });

      const fields = this.fieldList
        .filter(e => e.value)
        .map(e => e.key)
        .join(",");
      const groupIds = this.isAllGroup
        ? -1
        : this.groupList
            .filter(e => e.value)
            .map(e => e.id)
            .join(",");

      const token = localStorage.getItem("access_token");
      const baseUrl = process.env.VUE_APP_API_SERVER_URL;
      window.location.href = `${baseUrl}/api/config/contact/export/download/csv?fields=${fields}&groupIds=${groupIds}&access_token=${token}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-config-title {
  font-size: 1rem !important;
  font-weight: bold;
}
.cr-config-divider {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
